import React, { useState } from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import PlaidLinkComponent from "./PlaidLinkComponent";
import PlaidLinkComponent1 from "./PlaidLinkComponent_1";
import { Checkout } from "./PaddleInit";
import "./App.css";

let STRIPE_PUBLIC_KEY = "pk_live_51NELA5IYwNF1iWzrJuLJCkT7L1kCqsMOeJJ5XK4w5AiIbUwDyoOm7RdCVbDmSjaVcoAxkF6YTPKafIupjt5aHn9y00hZ2Wup66"
let CLIENT_SECRET = "seti_1R7IKSIYwNF1iWzrPetVKLkk_secret_S1L0Z0a6oYRrn3itsLSp4llPjwQGglm";

const CheckoutForm = () => {

 
  // let primary_account_id = "acct_1QjF9kCiwomjpeV1";

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {

      const result = await stripe.collectBankAccountForSetup({
        clientSecret: CLIENT_SECRET,
        params: {
          payment_method_type: "us_bank_account",
          payment_method_data: {
            billing_details: {
              name: "Jenny Rosen",
              email: "jenny@example.com",
            },
          },
        },
        expand: ["payment_method"],
      });

      console.log("result", result);

      if (result.setupIntent.status === "requires_confirmation") {
        const confirmationResult = await stripe.confirmUsBankAccountSetup(
          result.setupIntent.client_secret
        );
        console.log("Confirmation result:", confirmationResult);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTrial = () => {
    alert("trial");
  };

  return (
    <div className="payment-container">
      <div className="payment-card">
        <h1>Complete Your Payment</h1>
        <p className="subtitle">Securely process your payment using bank account</p>
        
        <form onSubmit={handleSubmit} className="payment-form">
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              defaultValue="Keval G"
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              defaultValue="kg@aveosoft.com"
              className="form-input"
              required
            />
          </div>

          <button
            type="submit"
            className="payment-button"
            disabled={!stripe || !elements || isLoading}
          >
            {isLoading ? "Processing..." : "Pay Now"}
          </button>

          {errorMessage && (
            <div className="error-message">
              {errorMessage}
            </div>
          )}
        </form>

        <div className="security-info">
          <p>🔒 Secure Payment Processing</p>
          <p>Your information is encrypted and secure</p>
        </div>
      </div>
    </div>
  );
};

const stripePromise = loadStripe(
  STRIPE_PUBLIC_KEY,
  // {
  //   stripeAccount: "acct_1QjF9kCiwomjpeV1",
  // }
);

const App = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default App;
